@import '../variables';

.container {
  display: flex;
  height: 50px;
  border-top: 2px solid $color_primary-light;
}

.link {
  height: 100%;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:active {
    background-color: $color_primary-light;
  }
}

.divider {
  margin: 10px 0;
  border: 0;
  border-left: 2px solid $color_primary-light;
}

.logo {
  height: 30px;
  display: inline-block;
  vertical-align: middle;
}

.webPushToggle {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0 20px;
}

.webPushToggleButtonOn {
  fill: $color_white;
}

.webPushToggleButtonOff {
  fill: $color_white_alpha;
}
