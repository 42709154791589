@import '../variables';

.select {
  position: relative;
  flex: 1;
  color: #fff;
}

.selectButton {
  width: 100%;
  height: 100%;
  border: 0;
  font-family: inherit;
  padding: 0;
  background-color: transparent;
  font-size: inherit;
  outline: none;
  color: inherit;

  &:active {
    background-color: $color_primary-light;
  }
}

.selectOptions {
  position: absolute;
  background-color: $color_primary-light;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
  z-index: 999;
}

.selectOption {
  display: block;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  border: 2px solid $color_primary;
}
