@import '../../variables';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.input {
  max-width: 200px;
  align-self: center;
  text-align: center;
  font-size: 20px;
  display: block;
  height: 40px;
  border: none;
  outline: none;
  color: $color_white;
  background: $color_white_alpha;
  border-radius: 20px;

  &:focus {
    outline: none;
  }
}

.button {
  background-color: $color_white;
  font-size: 14px;
  align-self: center;
  margin-top: 20px;
  height: 40px;
  padding: 0 30px;
  border-radius: 20px;
  border: none;
  outline: none;
  cursor: pointer;
}
