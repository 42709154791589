@import './variables';

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url('./assets/open-sans-regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'), url('./assets/open-sans-800.woff2') format('woff2');
}

html {
  background-color: $color_primary;
  height: 100%;
  font-family: 'Open Sans', sans-serif;
}

body {
  color: $color_primary;
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

a,
a:active {
  color: $color_primary;
  text-decoration: none;
}
